import React, { useEffect } from 'react';
import { useLocation } from 'react-use';
import Spinner from '../cpt/EnchantQuiz/cpt/Spinner';

// TODO: Some sensible non-content here. Nontent.

function Index() {
  const { hostname } = useLocation();

  useEffect(() => {
    if (hostname.indexOf('telenor') > -1) {
      setTimeout(() => {
        window.location = 'https://telenor.no';
      }, 4000);
    } else if (hostname.indexOf('hurtigruten') > -1) {
      setTimeout(() => {
        window.location = 'https://www.hurtigruten.com/';
      }, 4000);
    } else {
      setTimeout(() => {
        window.location = 'https://www.evokedset.no/';
      }, 4000);
    }
  });
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner color="#ccc" />
    </div>
  );
}

export default Index;
